.App-header {
  background-image: url('../assets/images/gift/landscape/bgweb.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

@media only screen and (orientation: portrait) {
  .App-header {
    background-image: url('../assets/images/gift/portrait/bgmobile.png');
  }
}

/*@media only screen and (max-height:  46.1875em) {*/
/*  .App-header {*/
/*    background-image: url('../assets/images/background_mobile.png');*/
/*  }*/
/*}*/
