.p-input-1 {
    background-color: #9d6f46;
    border-radius: 10px;
    width: 72vw;
    height: 4vh;
    color: white;
    padding: 5% 5%;
    margin-bottom: 1%;
    margin-top: 1%;
    border: 1px solid #ccc;
}

.p-switchButton {
    background: none;
    border: none;
    font: inherit;
    cursor: pointer;
    text-decoration: underline;
    font-size: 75%;
    font-style: italic;
    color: black;
    width: auto;
}

.p-input-2 {
    height: 20vh;
    margin-top: 5px;
    margin-bottom: 3px;
}

.p-hello {
    background-image: url("../../assets/images/gift/portrait/khung.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    padding-bottom: 10vh;
    padding-top: 18vh;

    margin-left: auto;
    margin-right: auto;

    height: 79vh;
}

.p-button-check {
    width: 7vw;
    height: 4vh;
}

.p-button-apply {
    width: 15vw;
    height: 4vh;
}

.p-text-right {
    text-align: right;
}

.p-text-red {
    padding-left: 17vw;
    color: darkred;
    font-weight: bold;
}

.p-text-left {
    text-align: left;
}

.p-ketqua {
    z-index: 2;
    position: absolute;
    width: 70vw;
}

.p-avatar {
    object-fit: cover;
    width: 100%;
}

.p-name {
    font-size: 20px;
    margin-bottom: 0px;
    color: gold;
}

.p-uid {
    font-size: 20px;
    color: cyan;
}

@media only screen and (min-device-width: 538px) and (max-device-width: 1024px) and (orientation : portrait) {
    .p-input-1 {
        background-color: #9d6f46;
        border-radius: 10px;
        width: 60vw;
        height: 4vh;
    }

    .p-input-2 {
        height: 20vh;
        margin-top: 2px;
        margin-bottom: 3px;
    }

    .p-ketqua {
        z-index: 2;
        position: absolute;
        width: 50vw;
    }

    .p-avatar {
        object-fit: cover;
        width: 100%;
    }
}

@media (max-width: 280px) and (max-height: 653px) and (max-aspect-ratio: 415/196) {
    .p-input-1 {
        background-color: #9d6f46;
        border-radius: 10px;
        width: 66vw;
        height: 2.5vh;
    }

    .p-input-2 {
        height: 17vh;
        margin-top: 2px;
        margin-bottom: 2px;
    }

    .p-hello {
        padding-top: 19.5vh;
    }
}