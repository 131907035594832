.image-button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  width: 30%;
}

.image-button img {
  width: 100%;
  height: 100%;
}

.image-button2 {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  width: 100%;
}

.image-button2 img {
  width: 70%;
  height: 70%;
}

.p-image-button2 {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  width: 100%;
}

.p-image-button2 img {
  width: 50%;
  height: 70%;
}

/*!* Các hiệu ứng hover và active *!*/
/*.image-button:hover {*/
/*  !* Áp dụng hiệu ứng khi di chuột qua *!*/
/*}*/

/*.image-button:active {*/
/*  !* Áp dụng hiệu ứng khi nút được nhấn *!*/
/*}*/
