.c-input-1 {
    background-color: #9d6f46;
    border-radius: 10px;
    width: 25vw;
    height: 4vh;
    color: white;
    padding: 5% 5%;
    margin-bottom: 3%;
    margin-top: 3%;
    border: 1px solid #ccc;
}

.switchButton {
    background: none;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    text-decoration: underline;
    font-size: 75%;
    font-style: italic;
    color: black;
    width: auto;
}

.c-input-2 {
    height: 20vh;
    margin-top: 2px;
    margin-bottom: 3px;
}

.hello {
    background-image: url("../../assets/images/gift/landscape/khung.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    padding-bottom: 10vh;
    padding-top: 16.5vh;

    margin-left: auto;
    margin-right: auto;

    height: 75vh;
}

.c-button-check {
    width: 7vw;
    height: 4vh;
}

.c-button-apply {
    width: 15vw;
    height: 4vh;
}

.text-right {
    text-align: right;
}

.text-red {
    color: darkred;
    font-weight: bold;
}

.text-left {
    text-align: left;
}

.ketqua {
    z-index: 2;
    position: absolute;
    width: 25vw;
}

.avatar {
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.name {
    font-size: 100%;
    margin-bottom: 0;
    color: gold;
}

.uid {
    font-size: 100%;
    color: cyan;
}

@media only screen and (max-height:  46.1875em) and (orientation: landscape) {
/*@media only screen and (min-device-width: 538px) and (max-device-width: 1024px) and (orientation : portrait) {*/
    .p-input-1 {
        background-color: #9d6f46;
        border-radius: 10px;
        width: 60vw;
        height: 4vh;
    }

    .p-input-2 {
        height: 20vh;
        margin-top: 2px;
        margin-bottom: 3px;
    }

    .hello {
        background-image: url("../../assets/images/gift/landscape/khung.png");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;

        padding-bottom: 10vh;
        padding-top: 20vh;

        margin-left: auto;
        margin-right: auto;

        height: 85vh;
    }
}